.h2_rules {
    font-size: 24px;
    font-weight: 500;
    border-left: 2px solid red;
    padding-left: 10px;
}

.li-rules {
    padding-top: 4px;
    padding-bottom: 4px;
    gap: 4px;

    span {
        font-weight: 500;
    }
}