.team {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  /* margin: 2rem 6rem; */
  align-items: center;
}

.production img {
  height: 300px;
  margin: 0.5rem;
}

.production h1 {
  text-transform: uppercase;
  text-align: center;
  color: #4c4c4c;
  font-size: 25px;
  /* margin-bottom: 1rem; */
  font-weight: 400;
  padding: 2rem 0 0.5rem 2.5rem;
  letter-spacing: 1px;
  /* color: #6c757d; */
}

.management img {
  height: 300px;
  margin: 0.5rem;
}

.management h1 {
  text-transform: uppercase;
  text-align: center;
  font-size: 25px;
  color: #4c4c4c;
  /* margin-bottom: 1rem; */
  font-weight: 400;
  padding: 2rem 0 0.5rem 2.5rem;
  letter-spacing: 1px;
  /* color: #6c757d; */
}

.infraStructureContent h2 {
  /* text-align: center; */
  text-transform: uppercase;
  margin: 1rem 0;
  color: #4c4c4c;
}

.infraStructureContent {
  margin: 2rem 0;
}

.infraStructureContent h2 span {
  border-bottom: 1px solid black;
  /* font-weight: 400; */
}
.background {
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 6rem;
}

.background h3 {
  margin-bottom: 1rem;
}

.background p {
  margin-bottom: 1rem;
  line-height: 28px;
  font-size: 15px;
}

.insideContainer {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
}

.insideContainer img {
  margin: 0 0.5rem;
  height: 1.7rem;
}

.insideContainer span {
  color: #4c4c4c;
}

.row {
  display: flex;
  margin: 1rem 0;
}

.link {
  text-decoration: none;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1150px) {
  .team {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 950px) {
  .flex {
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .background {
    padding: 2rem;
  }
}

@media screen and (max-width: 570px) {
  .production img {
    height: 200px;
    margin: 0.5rem;
  }

  .production h1 {
    font-size: 21px;
  }

  .management img {
    height: 200px;
    margin: 0.5rem;
  }

  .management h1 {
    font-size: 21px;
  }
}
