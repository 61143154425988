@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

.container h1 {
  padding: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
}

.container h1 span {
  border-bottom: 0.3rem solid #cc1f26;
}

.noticeLink {
  text-decoration: none;
  color: #333;
}

.noticeBoard {
  /* position: relative; */
  width: 100%;
  height: 21.5rem; /* Adjust height as needed */
  overflow: hidden;
  background-color: #f4f4f4; /* Adjust background color as needed */
  padding: 1rem;
}

.notices {
  display: flex;
  flex-direction: column;
  animation: scroll 10s linear infinite; /* Adjust duration as needed */
}

.notice {
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid #b1acac;
  display: flex;
  align-items: center;
  font-size: 1rem;
  background-color: #f4f4f4;
}

.date {
  font-weight: 600;
  font-size: 0.8rem;
}
