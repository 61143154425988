@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  /* font-family: "Kalinga", "Arial", sans-serif; */
  /* font-family: "Great"; */
  /* font-family: "Varela"; */
  /* font-family: "Blinker"; */
  /* font-family: auto; */
}



.fadeanimation {
  animation-name: fadeanimation;
  animation-iteration-count: 1;
  animation-duration: 300ms;
  scale: 1.2;
  background-color: white;
  transition: all;

  color: black;
  opacity: 1;
}

@keyframes fadeanimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}








/* logoanimation */
.animation-for-brand {
  animation: animation-logo 5s linear infinite;

}


@keyframes animation-logo {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.lineonhover {
  position: relative;
}

.lineonhover:hover::before {
  display: block;
}

.lineonhover::before {
  content: "";
  position: absolute;
  display: none;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 2px;
  background-color: #000;
  opacity: 1;
  animation-name: border;
  animation-iteration-count: 1;
  animation-duration: 300ms;
  animation-direction: ease-out;
}

@keyframes border {
  0% {
    width: 0;

  }

  100% {
    width: 100%;
  }
}


.infinite-scroll-container {
  /* animation-name: translate-x-infinite;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  animation: translate-x-infinite 20s linear infinite
}

@keyframes translate-x-infinite {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.client-item {
  flex: 0 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  transition: transform 0.3s;
}

.client-item img {
  width: 200px;
  height: 100px;
  transition: transform 0.3s;
}

.client-item:hover {
  transform: scale(1.2);
}