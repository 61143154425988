.industries {
  padding: 0 2rem;
  margin-top: 160px;
}

.ironSteel h1 {
  text-align: center;
  margin: 1rem;
}

.box {
  display: flex;
}

.content {
  padding-left: 3rem;
}

.content p {
  line-height: 1.5rem;
}

.imageDiv {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.imageDiv img {
  height: 20rem;
}

.secondBox {
  margin-top: 1rem;
}

.bof {
  margin-top: 1rem;
}

.bofContainer h2 {
  margin: 1rem 0;
}

.bofContainer p {
  line-height: 1.5rem;
}

.bofImage {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.bofImage img {
  height: 15rem;
}

.bofContent {
  margin-top: 1rem;
}

.bofContent h2 {
  margin: 1rem 0;
}

.bofContent p {
  line-height: 1.5rem;
}

.ironCoke {
  margin: 1rem 0;
}

.ironCoke h1 {
  margin: 1rem;
}

.cokeOven h2 {
  margin-bottom: 1rem;
}

.cokeOvenContainer {}

.cokeOvenContent {}

.cokeOvenContent p {
  line-height: 1.5rem;
}

.bf {
  margin-top: 1rem;
}

.bfContainer {
  margin-top: 1rem;
}

.bfContent {
  padding-left: 3rem;
}

.bfContent p {
  line-height: 1.5rem;
}

.Content {
  margin-top: 1rem;
}

.Content p {
  line-height: 1.5rem;
}

.transferLadle {
  margin-top: 2rem;
}