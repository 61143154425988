@media screen and (max-width:680px) {
    .insidecourses {
        padding: 0px;
        /* padding-right: 30px; */
    }

    .tabel-div {
        width: 90vw !important;
        padding-right: 30px;
    }


    .course-content {
        width: 286px;
    }

    .course-heading {
        font-size: 25px;
    }
}