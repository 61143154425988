.blackgradient {
    background-color: black;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.5;
}

.big_img_main {
    img {
        z-index: 7;
    }
}