.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f4f4f4;
  color: #333;
}

.pageNotFound h1 {
  font-size: 10rem;
  margin: 0;
}

.pageNotFound h2 {
  font-size: 3rem;
  margin: 0;
}

.pageNotFound p {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.homeLink {
  text-decoration: none;
  font-size: 1.2rem;
  color: #007bff;
  margin-top: 1rem;
}

.homeLink:hover {
  text-decoration: underline;
}
