.btn {
  color: #fff;
  background-color: #007bff;
  font-weight: 400;
  text-align: center;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 3px;
  font-size: 13px;
  padding: 1rem 2rem;
  margin: 1rem 0;
  width: 25rem;
  letter-spacing: 1px;
}

@media screen and (max-width: 1010px) {
  .btn {
    width: 25rem;
  }
}

@media screen and (max-width: 450px) {
  .btn {
    width: 20rem;
  }
}
