.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding: 0 2rem;
  /* background-image: url("../assests/imgs/office1.jpg"); */
  /* background-image: linear-gradient(
        to right bottom,
        rgba(112, 107, 107, 0.5),
        rgba(1, 10, 11, 0.803)
      ),
      url(../assests/img/banner-poster.jpg); */
  font-weight: 100;
  height: 480px;
  margin-top: 130px;
  width: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner h1 {
  color: #fff;
  font-size: 6rem;
  font-weight: 500;
  letter-spacing: 2px;
  margin: 1rem;

  padding-left: 20px;
  padding-right: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-transform: uppercase;
  text-align: center;
  animation-name: animation-fade-animation;
  animation-iteration-count: 1;
  animation-duration: 300ms;
  animation-timing-function: ease-in;

}


@keyframes animation-fade-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.typingEffect {
  /* Hide overflow */
  overflow: hidden;
  /* Prevent text from wrapping */
  white-space: nowrap;
  /* Blinking cursor effect */
  /* border-right: 2px solid #fff; */
  animation: typing 3s steps(40, end);
  transition: transform 0.5s ease;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .banner h1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 735px) {
  .banner h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 640px) {
  .banner h1 {
    font-size: 35px;
  }
}

@media screen and (max-width: 580px) {
  .banner h1 {
    font-size: 23px;
  }
}



@media screen and (max-width: 380px) {
  .banner h1 {
    font-size: 20px;
  }
}



.main-div::before {
  content: "";
  width: 100%;
  left: 0;
  right: 0;
  height: 100%;
  position: absolute;
  background-color: black;
  z-index: 20;
}