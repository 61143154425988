.bgfade {
    width: auto;
    height: auto;
    position: relative;
}

.bgfade::after {
    content: "";
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(0,0,0,0.1), rgba(219, 221, 226, 0.495));
    z-index: 999;
    opacity: 0;
    border-radius: 0.3rem;
    transition: opacity 0.3s ease;
}

.bgfade:hover::after {
    opacity: 1;
}