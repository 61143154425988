.about {
  padding: 0 3rem;
}

.container {
  margin: 2rem 0 0.5rem 0;
}

.container h2 {
  /* text-align: center; */
  margin: 1rem 0;
  color: #4c4c4c;

  font-size: 2rem;
}

.container h2 span {
  border-bottom: 2px solid #4c4c4c;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
  /* font-weight: 400; */
}



.container p {
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  color: #4c4c4c;
  font-size: 15px;
  margin-bottom: 0.2rem;
  line-height: 28px;
}

.imageDiv {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: start;
}

.imageDiv img {
  height: 200px;
  border-radius: 10px;
}

.alignDiv {
  display: flex;
  align-items: end;
  justify-content: center;
  height: fit-content;
  margin-bottom: 20px;
}


.whiteshades::after {
  content: "ddd";
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  position: absolute;
  background-color: black;
}


.alignDivColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container1 h2 {
  text-align: center;
  margin: 1rem 0;
  margin-top: 40px;

  color: #4c4c4c;
}

.container1 h2 span {
  border-bottom: 1px solid black;
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  /* font-weight: 400; */
}

.directorContainer {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
}

.directors {
  text-align: center;
  height: 286px;
  width: 235px;
  /* background-color: rgb(255, 255, 255); */
}

.directors img {
  height: 200px;
  margin: 1rem;
}

.directors h3 {
  color: #4c4c4c;
  color: #333;
  font-weight: 400;
}

@media screen and (max-width: 1260px) {
  .about {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .imageDiv img {
    display: flex;
    flex-direction: column;
  }

  .alignDiv {
    flex-direction: column;
    align-items: center;
  }
}