.gradient-text {
    background: linear-gradient(to bottom, white, transparent, white);

    /* For Safari */

    /* For Safari */
    color: #b79a9a;
    /* For other browsers */
}

@media screen and (max-width:640px) {
    .header_text {
        font-size: 30px;
    }
}