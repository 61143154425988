.container {
  padding: 5rem 13rem;
}

.container h3 {
  margin: 1rem 0;
}

.box img {
  /* height: 17rem; */
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
  background-position: center;
  background-size: cover;
}

.box p {
  margin-bottom: 1rem;
  color: #333;
  font-size: 16px;
  line-height: 28px;
}

.container2 {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.box2 {
  text-align: center;
  position: relative;
  height: auto;
  width: 15rem;
  /* background-color: #333; */
}

.box2 img {
  margin: 0.5rem;
  height: 150px;
  position: relative;
}

.box2::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top right, rgba(255, 255, 255, 0.438), transparent);
  position: absolute;
  transition: all 250ms ease-in-out;
  top: 0;
  left: 0;
}

.box2:hover::after {
  opacity: 1;
  transition: all 250ms ease-in-out;
}

@media screen and (max-width: 1100px) {
  .container {
    padding: 5rem 10rem;
  }
}

@media screen and (max-width: 950px) {
  .container {
    padding: 5rem 7rem;
  }
}

@media screen and (max-width: 810px) {
  .container {
    padding: 1rem;
  }
}

@media screen and (max-width: 750px) {}