.address {
  padding: 1rem;
}

.address span {
  font-weight: 400;
}

.address p {
  font-weight: 300;
}

.address h3 {
  font-weight: 500;
  color: white;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #cc1f26;
}

.address h3 span {
  border-bottom: 2px solid white;
}

.underline {
  text-decoration: none;
}

.companyLocationMap {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
}

@media screen and (max-width: 1000px) {
}
