* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshowContainer {
  width: 100%;
  height: auto;

  position: absolute;
  top: 130px;


}

/* Hide the images by default */
.mySlides {
  display: none;
}

.active {
  display: block;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dotContainer {
  text-align: center;
  margin-top: -26px;
  /* Adjust this value as needed to move the dot container upward */
}

.dot:hover,
.active {
  background-color: #71717100;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.carouselImg {
  width: 100%;
  height: 100vh;
  background-position: center;
  object-fit: cover;
}

@keyframes fade {
  from {
    opacity: 0.9;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .carouselImg {
    width: 100vw;
    height: auto;
  }
}