.form h1 {
  padding: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
}

.form h1 span {
  border-bottom: 0.3rem solid #cc1f26;
}

.enquiryFrom {
  display: flex;
  width: 50vw;
  flex-direction: column;
  justify-content: center;
  background-color: #852124;
  padding: 1rem;
}

.enquiryFrom h2 {
  margin-bottom: 0.9rem;
  padding: 1rem;
}

.enquiryFrom h2 span {
  border-bottom: 2px solid black;
}

.enquiryFrom input {
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 1rem;
}

.inputClass {
  margin-right: 0.5rem;
}

.enquiryFrom textarea {
  width: 100%;
  padding: 0.7rem;
  margin: 1rem 0;
}

.btn {
  border: none;
  font-weight: 500;
  width: 100%;
  padding: 0.7rem;
  color: #eeeeee;
  background-color: #cc1f26;
  margin: 2rem 0 1rem 0;
}

.btn:hover {
  color: #852124;
  background-color: #eeeeee;
}

.row input {
  width: 49%;
  padding: 0.7rem;
}

@media screen and (max-width: 865px) {
  .row input {
    width: 100%;
    padding: 0.7rem;
  }

  .enquiryFrom {
    width: 80vw;
  }

  .form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
