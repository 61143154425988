.h1_gallery {
    font-size: 3rem;
    color: #cc1f26;
}


@media screen and (max-width:640px) {
    .allcontent-gallery {
        margin-top: 40px !important;
    }
}