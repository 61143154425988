.container {
  padding: 1rem 2rem;
}

.container h2 {
  /* text-align: center; */
  margin: 2rem 0;
  color: #4c4c4c;
  font-family: 'Great vibes' cursive;
}

.container h2 span {
  border-bottom: 1px solid black;
  font-family: Arial, Helvetica, sans-serif;
}

/* font-weight: 500; */




.customerList {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.customerList h2 {
  margin: 1rem 0;
  font-weight: 500;

}

.table {
  border: 1px solid black;
  padding: 1rem 1.5rem;
  background-color: #f6f6f6;
}

.table p {
  font-family: auto;
  font-weight: 500;
}

.monitoring {
  padding: 1rem;
}

.monitoring p {
  color: #4c4c4c;
}

.monitoring h1 {
  margin: 2rem 0;
  font-weight: 500;
  color: #4c4c4c;

  font-family: Arial, Helvetica, sans-serif;
}

.monitoringImg img {
  height: 300px;
  margin: 1rem 1rem 1rem 0;
}

@media screen and (max-width: 600px) {
  .monitoring h1 {
    margin: 1rem 0;
    font-size: 23px;
    text-align: center;
  }

  .customerList h2 {
    font-size: 23px;
    margin: 0.5rem 0;
  }

  .monitoringImg {
    text-align: center;
  }

  .monitoringImg img {
    height: 200px;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
}

@media screen and (max-width: 450px) {
  .monitoring h1 {
    margin: 0.5rem 0;
    font-size: 18px;
  }

  .customerList h2 {
    font-size: 18px;
  }

  .table {
    padding: 0.5rem 0.7rem;
  }
}

@media screen and (max-width: 450px) {
  .monitoringImg img {
    height: 150px;
  }
}