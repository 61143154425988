.powerplantImg {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.powerplantImg img {
  height: 20rem;
  border-radius: 10px;
}