.productContent {
  background-color: #e1e8ef;
}

.productContent p {
  font-size: 25px;
  font-weight: 700;
  padding: 2rem 0 0.5rem 2.5rem;
  letter-spacing: 1px;
  /* color: #6c757d; */
}

.infraImg {
  padding: 2rem 3rem;
  background-color: #e1e8ef;
  display: flex;
  /* justify-content: space-between; */
  /* justify-content: ; */
  flex-wrap: wrap;
}

.content {
  margin: 1rem;
}

.content h3 {
  /* margin: 1rem 0; */
  text-transform: uppercase;
}

.infraStructureContent {
  padding: 0 3rem;
  font-family: Arial, Helvetica, sans-serif;
}

.infraStructureContent p {
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #4c4c4c;
  font-size: 15px;
}

.infraStructureContent h2 {
  /* text-align: center; */
  text-transform: uppercase;
  margin: 1rem 0;
  color: #4c4c4c;
}

.infraStructureContent h2 span {
  border-bottom: 1px solid black;
  /* font-weight: 400; */
}

/*----------------------------------------------------------------------------*/

.textAlign {
  text-align: center;
  text-transform: uppercase;
}

.infraImg {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/*box-3 and content-3*/
/* Container for the box */
.box3 {
  position: relative;
  overflow: hidden;
  margin: 0.5rem;
}

/* Image inside the box */
.box3 img {
  height: 250px;
  width: 250px;
}

/* Content inside the box */
.content3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  z-index: 1;
  /* Ensure the content is above the pseudo-element */
  transition: transform 0.5s ease-in-out;
}

.content3 h3 {
  font-size: 1rem;
  font-weight: 800;
}

/* Pseudo-element for background color */
.box3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* Initially covers half of the box */
  height: auto;
  /* Initial semi-transparent background color */
  background: linear-gradient(to top right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
  /* Ensure it is behind the content */
  z-index: 2;
  /* Smooth transition for height change */
  transition: height 0.5s ease-in-out;
}

/* Hover state for the pseudo-element */
.box3:hover::before {

  /* Cover the full box on hover */
  transition: height 0.5s ease-in-out;
  /* Smooth transition for height change */
}



.box3 .content3 h3 {
  /* Smooth transition for the transform property */
  transition: transform 0.5s ease-in-out;
  font-size: 1rem;
}

@media screen and (max-width: 600px) {
  .infraStructureContent {
    padding: 0 1rem;
  }

  .infraImg {
    padding: 0.5rem;
  }
}