/* ProductionSlider.module.css */

.header {
  text-align: center;
  margin-bottom: 0.8rem;
  font-weight: 400;
}

.slider {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
}

.slideContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

.slide {
  /* position: absolute; */

  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1s ease-in-out;
}

.image {
  height: 300px;
  object-fit: cover;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}

.next {
  right: 0;
  border-radius: 3px 3px 0 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 570px) {
  .image {
    height: 300px;
  }
}

@media screen and (max-width: 490px) {
  .image {
    height: 200px;
  }

  .header {
    font-size: 25px;
  }
}

@media screen and (max-width: 350px) {
  .image {
    height: 170px;
  }

  .header {
    font-size: 25px;
  }
}
