.background {
  position: fixed;
  background-color: #3333337d;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.appNav {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.359);
  padding: 1rem;
  /*width: 100vw;*/
  height: 100vh;
  /* top: 0; */
  z-index: 1;
  right: 0;
}

.appNav input {
  width: 100%;
  padding: 0.5rem;
  outline: none;
}
.appNav li {
  list-style: none;
  /* text-align: center; */
  padding: 0.5rem;
  /* border: 1px solid rgba(255, 255, 255, 0.281); */
}

.appNav li:hover {
  color: #333;
  background-color: #c2c2c2;
}

.inputSearch input {
  cursor: pointer;
}

.navlink {
  text-decoration: none;
  letter-spacing: 0.5px;
  color: #fff;
}

.navlink li {
  padding: 0.5rem 1rem;
}

.navlink li:hover {
  background-color: #fff;
}

.navlink li span img {
  background-color: #333333;
  height: 21px;
  margin-right: 5px;
}

.navlink:hover {
  /* color: #333; */
  /* background-color: #fff; */
  /* background-color: rebeccapurple; */

  /* border-radius: 3px; */
}
