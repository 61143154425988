.imageUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileInput {
  margin: 20px 0;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.imagePreview {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.image {
  max-width: 100%;
  max-height: 400px;
  border-radius: 10px;
}

.uploadBtn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.uploadBtn:hover {
  background-color: #0056b3;
}
