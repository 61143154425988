.deskmain {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;

}

.img-content {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 15px;

    .img-div {
        /* padding: 10px; */
        width: 170%;
    }
}


.position-name {
    border-left: 3px solid red;
}



@media screen and (max-width:640px) {
    .img-content {
        flex-direction: column;

        .img-div {
            width: 89%;
        }
    }

    .deskmain {
        margin-top: 73px;
    }
}