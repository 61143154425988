.container {
  padding: 5rem 13rem;
}

.container h3 {
  margin: 1rem 0;
}

.box img {
  height: 17rem;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.box p {
  margin-bottom: 1rem;
  color: #333;
  font-size: 16px;
  line-height: 28px;
}

@media screen and (max-width: 1100px) {
  .container {
    padding: 5rem 10rem;
  }
}

@media screen and (max-width: 950px) {
  .container {
    padding: 5rem 7rem;
  }
}

@media screen and (max-width: 750px) {
  .container {
    padding: 1rem;
  }
}
