.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.container {
  padding: 1rem 3rem;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  background-color: #e1e8ef;
}

.fontchange {
  font-family: Arial, Helvetica, sans-serif !important;
}


.box {
  margin: 0.5rem 1rem 0 1rem;
}

.box img {
  height: 250px;
  width: 250px;
  margin-bottom: 0.2rem;
}

.box h3 span {
  border-bottom: 1px solid black;
  font-weight: 400;
}

.box h3 {
  text-align: center;
  margin-bottom: 1rem;
  color: #4c4c4c;
}

.description {
  padding: 1rem 10rem;
  text-rendering: optimizeLegibility;
  color: #4c4c4c;
  margin-bottom: 0.5rem;
}

.description p {
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-family: auto;
  word-break: break-word;
  line-height: 21px;
}

.infraImg {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/*box-3 and content-3*/
/* Container for the box */
.box3 {
  position: relative;
  overflow: hidden;
  margin: 0.5rem;
}

/* Image inside the box */
.box3 img {
  height: 250px;
  width: 250px;
}

/* Content inside the box */
.content3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  z-index: 1;
  /* Ensure the content is above the pseudo-element */
  transition: transform 0.5s ease-in-out;
}

.content3 h3 {
  font-size: 15px;
  font-weight: 100;
}

.box3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* Initially covers half of the box */
  height: 20%;
  /* Initial semi-transparent background color */
  background: rgba(51, 51, 51, 0.65);
  /* Ensure it is behind the content */
  z-index: 0;
  /* Smooth transition for height change */
  transition: height 0.5s ease-in-out;
}

/*-----------------------------Read-More-----------------------------*/
.readMoreBtn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.readMoreBtn:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 1000px) {
  .description {
    padding: 1rem 3rem;
  }
}

@media screen and (max-width: 690px) {
  .container {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .description {
    padding: 1rem;
  }
}