.actionbtn {
    .view {
        background-color: #ffff9e;
        transition: all 200ms ease-in-out;

        &:hover {
            transition: all 200ms ease-in-out;
            background-color: yellow;
        }
    }



    .download {
        background-color: #fd7373;
        transition: all 200ms ease-in-out;

        &:hover {
            transition: all 200ms ease-in-out;
            background-color: red;
        }
    }
}



@media screen and (max-width:640px) {
    .actionbtn {
        flex-direction: column;
    }

    .view {
        background-color: #ffff9e;
        transition: all 200ms ease-in-out;
        width: 100%;
        &:hover {
            transition: all 200ms ease-in-out;
            background-color: yellow;
        }
    }

    .download {
        background-color: #fd7373;
        transition: all 200ms ease-in-out;
        padding-left: 10px;
        padding-right: 10px;

        &:hover {
            transition: all 200ms ease-in-out;
            background-color: red;
        }
    }
}