.infraImg {
  padding: 2rem 3rem;
  display: flex;
  background-color: #e1e8ef;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.box {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  /* border-radius: 10px; */
  width: 13rem;
  height: 17rem;
  margin: 0.5rem 0;
  overflow: hidden;
}

.box img {
  /* border-radius: 10px; */
  height: 200px;
  /* width: 300px; */
  transition: transform 0.2s ease;
}

.box img:hover {
  transform: scale(1.2);
}

.content {
  margin: 1rem;
}

.content h3 {
  /* margin: 1rem 0; */
}

.paragrap p {
  margin: 1rem;
  letter-spacing: 1px;
  line-height: 28px;
}

.textAlign {
  text-align: center;
  padding: 0.5rem 0;
}

.infraStructureContent {
  padding: 1rem 3rem;
}
/* .infraStructureContent p {
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  color: #4c4c4c;
  font-size: 15px;
} */

 
 

/*----------------------------------------------------------------------------*/

.textAlign {
  text-align: center;
  text-transform: uppercase;
}

.infraImg {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/*box-3 and content-3*/
/* Container for the box */
.box3 {
  position: relative;
  overflow: hidden;
  margin: 0.5rem;
}

/* Image inside the box */
.box3 img {
  height: 250px;
  width: 250px;
}

/* Content inside the box */
.content3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  z-index: 1; /* Ensure the content is above the pseudo-element */
  transition: transform 0.5s ease-in-out;
}

.content3 h3 {
  font-size: 21px;
  font-weight: 400;
}

/* Pseudo-element for background color */
/*  
.box3 .content3 h3 {
  /* Smooth transition for the transform property */
 
@media screen and (max-width: 600px) {
  .infraStructureContent {
    padding: 0 1rem;
  }
  .infraImg {
    padding: 0.5rem;
  }
}
