.render-text {
    animation-name: render-text;
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;

}

@keyframes render-text {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}