.infraStructureContent {
  padding: 0 3rem;
}
.infraStructureContent p {
  text-rendering: optimizeLegibility;
  /* letter-spacing: 1px; */
  line-height: 27px;
  color: #4c4c4c;
  font-size: 15px;
  margin-bottom: 0.5rem;
}

.infraStructureContent h2 {
  /* text-align: center; */
  text-transform: uppercase;
  margin: 1.5rem 0;
  color: #4c4c4c;
}

.infraStructureContent h2 span {
  border-bottom: 1px solid black;
  /* font-weight: 400; */
}
/*----------------------------------------------------------------------------*/
.heading {
  /* background-color: #deebff; */
  text-align: center;
  color: #4c4c4c;
  /* padding: 0.4rem; */
}

.textAlign {
  text-align: center;
  text-transform: uppercase;
}

.infraImg {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/*box-3 and content-3*/
/* Container for the box */
.box3 {
  position: relative;
  overflow: hidden;
  margin: 1rem;
}

/* Image inside the box */
.box3 img {
  height: 250px;
  width: 250px;
}

/* Content inside the box */
.content3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  z-index: 1; /* Ensure the content is above the pseudo-element */
  transition: transform 0.5s ease-in-out;
}

.content3 h3 {
  font-size: 21px;
  font-weight: 200;
}
