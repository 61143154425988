.infraImg {
  padding: 0.5rem 3rem;
  display: flex;
  background-color: #e1e8ef;
  /* justify-content: space-between; */
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.infraStructureContent {
  padding: 0 3rem;
  margin-top: 3rem;
}

.infraStructureContent p {
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  line-height: 28px;
  color: #4c4c4c;
  font-size: 15px;
  margin-bottom: 0.5rem;
}

.infraStructureContent h2 {
  /* text-align: center; */
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  color: #4c4c4c;
}

.infraStructureContent h2 span {
  border-bottom: 1px solid black;
  /* font-weight: 400; */
}

/*----------------------------------------------------------------------------*/
.heading {
  /* background-color: #deebff; */
  text-align: center;
  color: #4c4c4c;
  /* padding: 0.4rem; */
}

.textAlign {
  text-align: center;
  text-transform: uppercase;
}

.infraImg {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding: 1rem;
}

/*box-3 and content-3*/
/* Container for the box */
.box3 {
  position: relative;
  overflow: hidden;
  margin: 1rem;
}

/* Image inside the box */
.box3 img {
  height: 250px;
  width: 250px;
}

/* Content inside the box */
.content3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  text-align: left;
  font: 600;
  font-size: 1.5rem;
  color: #4c4c4c;
  z-index: 1;
  /* Ensure the content is above the pseudo-element */
  transition: transform 0.5s ease-in-out;
}

.content3 h3 {
  font-size: 1.2rem;

  font-weight: 400;
}

/* Pseudo-element for background color */
.box3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* Initially covers half of the box */
  height: 20%;
  color: #4c4c4c;
  font-size: 1.2rem !important;
  /* Initial semi-transparent background color */
  background: linear-gradient(to bottom left, rgb(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
  /* Ensure it is behind the content */
  z-index: 0;
  /* Smooth transition for height change */
  transition: all 200ms ease-in-out;
}


/* .box3:hover::before {
  background: linear-gradient(to bottom left, rgba(255, 255, 255, 0.8), rgb(255, 255, 255, 0));
 
  transition: all 200ms ease-in-out;
 
} */



.box3 .content3 h3 {
  /* Smooth transition for the transform property */
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  font-weight: 600;
}

.paragraph {
  margin: 1rem 0;
}

@media screen and (max-width: 550px) {
  .infraStructureContent {
    padding: 0 1rem;
  }
}