.academic_head {
    font-size: 2rem;
    font-size: 550;

    h2 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width:640px) {
    .tabel-div {
        width: 100vw;
        overflow: scroll;
        height: auto;
        padding-left: 30px;
        padding-right: 30px;
    }

    .main-academic {
        margin-top: 70px;
    }

    .academic_head {
        font-size: 2rem;
        font-size: 550;
    
        h2 {
            font-size: 25px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }


}