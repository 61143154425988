.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  gap: 20px;
  background-color: #eeeeee;
}

.box {
  width: 14rem;
  height: 21rem;
  padding: 1rem;
  border: 3px solid;
  border-radius: 0.5rem;
  position: relative;
  transition: all 200ms ease-in-out;
}

.box:hover {
  transition: all 200ms;
  border: 3px solid;
}



.box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: linear-gradient(to bottom right, rgba(48, 44, 44, 0), rgba(63, 59, 59, 0.137));
}

.box:hover::after {
  opacity: 0;
}

.box h4 {
  margin-bottom: 1rem;
  font-weight: 650;
  font-size: 18px;
  width: 100%;
}

.box p {
  font-size: 15px;
  letter-spacing: 0.5px;
}

.box img {
  height: 5rem;
  margin-bottom: 1rem;
  transition: all 200ms;
}

.box:hover img {
  scale: 1.2;
  transition: all 200ms ease-in-out;
}

@media screen and (max-width: 550px) {
  .container {
    padding: 0.5rem 0;
  }

  .box {
    width: 11rem;
    height: 22rem;
  }
}