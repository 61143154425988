.container {
  font-family: "Blinker";
  background-color: #cc1f26;
  color: #fff;
  padding: 0.1rem;
  height: auto;
  font-weight: 500;
  display: flex;
  height: fit-content;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0.5rem;
  position: absolute;
  width: 100%;
  top: 0;

}

.navHeadContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2rem;
}

.navHeadContent h3 {
  font-weight: 300;
}

.logo {
  width: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  height: 65px;
  border-radius: .5rem;
  background-color: white;
}

.navlogo img {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.callIcon {
  height: 1rem;
}

.mail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mail span {
  font-weight: 400;
  margin-right: 1rem;
  margin-left: 1rem;
}

.logo {
  display: flex;
  justify-content: start;
  align-items: center;
}

.logo span {
  margin: 0 0.5rem;
}

.logo span img {
  cursor: pointer;
  height: 1.5rem;

}

.fontW {
  font-weight: 300;
}

@media screen and (max-width: 1000px) {
  .navlogo {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .navHeadContent h2 {
    font-size: 1.2rem;
  }

  .logo span img {
    cursor: pointer;
    height: 50px;

  }

  .navHeadContent h5 {
    font-size: 0.9rem;
  }

  .navHeadContent h3 {
    font-size: 1rem;
  }

  .logo {
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .navHeadContent h2 {
    font-size: 1rem;
  }

  .navHeadContent h5 {
    font-size: 0.7rem;
  }

  .navHeadContent h3 {
    font-size: 0.8rem;
  }

  .logo {
    margin-right: 0.1rem;
  }

  img {
    height: 50px;
  }
}