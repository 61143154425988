.heading {
  background-color: #deebff;
  text-align: center;
  color: #333;
  padding: 0.4rem;
}

.contact {
  display: flex;
  justify-content: center;

  width: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0.5rem;
  padding-top: 0px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form input {
  border: 1px solid #d7d6d6;
  width: 25rem;
  font-size: 13px;
  color: #5b5b5b;
  outline: 0;
  margin: 0.5rem 0;
  border-radius: 3px;
  font-size: 13px;
  padding: 1rem 2rem;
  letter-spacing: 1px;
}
.form textarea {
  border: 1px solid #d7d6d6;
  width: 25rem;
  font-size: 13px;
  color: #5b5b5b;
  outline: 0;
  margin: 0.5rem 0;
  border-radius: 3px;
  font-size: 13px;
  padding: 1rem 2rem;
  letter-spacing: 1px;
}

.left {
  /* margin-top: 3rem; */
  margin-right: 3rem;
}

.right {
  
  /* margin-top: 3rem; */
  margin-left: 3rem;
  letter-spacing: 1px;
}

.header {
  margin: 1rem 0;
}

.header h6 {
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  color: #4c4c4c;
  font-size: 15px;
}

.header p {
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  color: #4c4c4c;
  font-size: 15px;
}

.textAlign {
  text-align: center;
  padding: 0.5rem 0;
}

.infraStructureContent {
  padding: 0 3rem;
}
.infraStructureContent p {
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  color: #4c4c4c;
  font-size: 15px;
}

.infraStructureContent h2 {
  /* text-align: center; */
  text-transform: uppercase;
  margin: 1rem 0;
  color: #4c4c4c;
}

.infraStructureContent h2 span {
  border-bottom: 1px solid black;
  /* font-weight: 400; */
}

.row {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 850px) {
  .infraStructureContent {
    padding: 0.5rem;
  }

  .contact {
    flex-direction: column;
    align-items: center;
  }

  .left {
    margin-top: 0;
    margin-right: 0;
  }

  .right {
    margin-top: 0;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .form input {
    width: 20rem;
  }

  .form textarea {
    width: 20rem;
  }
}
